import NumberedMosaic from './select/NumberedMosaic'
import SelectDevices from './select/SelectDevices'
import { DottedName } from 'Rules'

const mosaicQuestions: Array<{
	question: string
	description: string
	isApplicable: Function
	component: React.FunctionComponent
	dottedName: DottedName
}> = [
	{
		dottedName: "numérique . liste d'appareils",
		options: { defaultsToFalse: true },
		question: 'Quels appareils numériques de moins de 10 ans possèdes-tu ?',
		description: `
L'essentiel de l'empreinte du numérique réside dans les appareils que nous achetons. Renseigne ici tes appareils.


> ✨️ Par simplicité, ne renseignes que les appareils récents : un smartphone âgé de 10 ans a déjà été bien amorti. 
> Si tu l'as acheté d'occasion il y a 3 ans et qu'il avait déjà environ 2 ans, considère qu'il a 5 ans ! 
			`,
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('numérique') && dottedName.includes(' . présent'),
		component: SelectDevices,
	},
	{
		dottedName: "divers . électroménager . liste d'appareils",
		options: { defaultsToFalse: true },
		question:
			'Quels appareils électroménagers de moins de 10 ans possèdes-tu ?',
		description: `
L'essentiel de l'empreinte de l'électroménager réside dans les appareils que nous achetons.

> ✨️ Par simplicité, ne renseignes que les appareils récents : un réfrigérateur âgé de 10 ans a déjà été bien amorti.

Si tous tes appareils ne sont pas proposés dans cette liste, ce n'est pas grave, ce test ne se veut pas exhaustif.
			`,
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('divers . électroménager') &&
			dottedName.includes(' . présent'),
		component: SelectDevices,
	},
	{
		dottedName: 'logement . modes de chauffage',
		options: { defaultsToFalse: true },
		question: 'Comment est chauffé ton logement ?',
		description: `
Certains logements sont chauffés entièrement à l'électricité, d'autres sont entièrement chauffés av  ec du gaz, et plus rarement du bois ou du fioul.·
      
Dans d'autres situations encore, un logement peut être chauffé principalement à l'électricité, mais avec un appoint bois, par exemple.

Coches tous les modes que tu utilises.

			`,
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('logement . chauffage') &&
			dottedName.includes(' . présent'),
		component: SelectDevices,
	},
	{
		dottedName: 'transport . vacances',
		options: { defaultsToFalse: true },
		question: 'Que possèdes-tu pour tes week-ends, tes vacances ?',
		description: `
A compléter

			`,
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('transport . vacances') &&
			dottedName.includes(' . propriétaire'),
		component: SelectDevices,
	},
	{
		dottedName: 'alimentation . régime',
		question:
			'Choisis les plats de tes midis et dîners pour une semaine type',
		description: `

Choisis 14 plats qui représentent ta semaine type : 7 midi et 7 dîners. 

> Nous travaillons pour que les menus associés à tes repas soient les plus représentatifs de tes habitudes, n'hésites pas à aller plus loin en parcourant [la documentation](https://nosgestesclimat.fr/documentation/alimentation/plats).

			`,
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('alimentation . plats') &&
			dottedName.includes(' . nombre'),
		component: NumberedMosaic,
		options: { chipsTotal: 14 },
	},
	{
		dottedName: 'divers . textile',
		question: 'Quels vêtements achètes-tu neufs en général dans une année ?',
		isApplicable: (dottedName: DottedName) =>
			dottedName.includes('divers . textile') &&
			dottedName.includes(' . nombre'),
		component: NumberedMosaic,
	},
]

export default mosaicQuestions
