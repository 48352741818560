import emoji from 'react-easy-emoji'
import { useDispatch } from 'react-redux'
import { actionImg } from '../../components/SessionBar'
import { skipTutorial } from '../../actions/actions'

export default ({ value, unit }) => {
	const dispatch = useDispatch()
	return (
		<div className="ui__ card light colored content" css="margin-top: 1.6rem">
			<h1 css="display: flex; align-items: center">
				<img src={actionImg} css="width: 2rem" />
				Passer à l'action !
			</h1>
			<p>Tu as terminé ta simulation, {emoji('👏')} bravo !</p>
			<p>
				Tu connais maintenant ton empreinte, estimée à {value} {unit}, et
				tu a sûrement déjà des idées pour la réduire...
			</p>
			<p>
				Pour t'aider, nous te présenterons{' '}
				<strong>une liste d'actions</strong> :
			</p>

			<ul css="li {list-style-type: none;}">
				<li>{emoji('✅')} sélectionne celles qui t'intéressent</li>
				<li>
					{' '}
					{emoji('❌')} écarte celles qui te semblent trop ambitieuses ou
					déplacées.
				</li>
			</ul>
			<p>
				{emoji('💡')} Pour améliorer la précision, certaines actions te
				poseront quelques questions en plus.
			</p>
			<button
				className="ui__ button plain cta"
				onClick={() => dispatch(skipTutorial('actions'))}
			>
				Démarrer
			</button>
		</div>
	)
}
