import { Markdown } from 'Components/utils/markdown'
import about from 'raw-loader!./about.md'
import React from 'react'
import { Link } from 'react-router-dom'
import Meta from '../../components/utils/Meta'

export default () => (
	<section className="ui__ container" id="about">
		<Meta
			title="À propos"
			description={`
Ce simulateur te permet d'évaluer ton empreinte carbone individuelle annuelle totale et par grandes catégories (alimentation, transport, logement, divers, services publics, numérique), de la situer par rapport aux objectifs climatiques et surtout de passer à l’action à ton niveau avec des gestes personnalisés en fonction de tes réponses.

		`}
		/>

		<Markdown source={about} />
	</section>
)
