import animate from 'Components/ui/animate'
import LogoADEME from 'Images/LogoADEME'
import { useContext } from 'react'
import emoji from 'react-easy-emoji'
import { Link } from 'react-router-dom'
import NewsBanner from '../../components/NewsBanner'
import { openmojiURL } from '../../components/SessionBar'
import Meta from '../../components/utils/Meta'
import { TrackerContext } from '../../components/utils/withTracker'
import DocumentationButton from './DocumentationButton'
import Illustration from './images/ecolab-climat-dessin.svg'
import Marianne from './images/Marianne.svg'
import { useProfileData } from './Profil'

export default () => {
	const tracker = useContext(TrackerContext)
	return (
		<div
			css={`
				max-width: 850px;
				margin: 0 auto;
				border-radius: 1rem;
				padding: 0.4rem;
				h1 {
					margin-top: 0.3rem;
					font-size: 140%;
					line-height: 1.2em;
				}
				> div > a {
				}
				text-align: center;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				align-items: center;
				min-height: 85vh;
				footer {
					margin-top: auto;
				}
			`}
		>
			<Meta
				title="Connais-tu ton empreinte climat ?"
				description="Teste ton empreinte carbone, tout seul ou en groupe. Découvre la répartition de ton empreinte. Suis le parcours de passage à l'action pour la réduire."
				image="https://www.nosgestesclimat-pf.org/images/dessin-nosgestesclimat.png"
			/>
			<h1>Connais-tu ton empreinte climat ?</h1>
			<Illustration
				aira-hidden="true"
				css={`
					width: 60%;
					height: auto;
					border-radius: 0.8rem;
					@media (max-width: 800px) {
						width: 95%;
					}
				`}
			/>
			<div css="margin: 1rem 0">
				<div>
					<Link
						to="/simulateur/bilan"
						className="ui__ plain button cta"
						onClick={() =>
							tracker.push([
								'trackEvent',
								'NGC',
								'Clic CTA accueil',
								'Faire le test',
							])
						}
					>
						Faire le test
					</Link>
				</div>
				<div
					css={`
						display: none;
					`}
				>
					<Link
						to="/groupe"
						className="ui__ button small"
						onClick={() =>
							tracker.push([
								'trackEvent',
								'NGC',
								'Clic CTA accueil',
								'Faire le test à plusieurs',
							])
						}
					>
						{emoji('👥')} Faire le test à plusieurs
					</Link>
				</div>
			</div>

			<footer>
			    <div
					css={`
						display: none;/*flex*/
						align-items: center;
						justify-content: center;
						margin-bottom: 1rem;
						img {
							margin: 0 0.6rem;
						}
					`}
				>
					<a href="https://www.plan-climat-pf.org/" title="Un outil mis à disposition par le Service des Energies de la Polynésie française">
						<img
							css=""
							src="https://www.nosgestesclimat-pf.org/illustrations/logo-pcpf.png"
							alt="Logo du Plan Climat de la Polynésie française"
						/>
					</a>
				</div>
				<div
					css={`
						display: flex;/*none*/
						align-items: center;
						justify-content: center;
						margin-bottom: 1rem;
						img {
							margin: 0 0.6rem;
						}
					`}
				>
					<span css="font-size:0.8em;color:#97a6d5;width:70%">Un outil de calcul d'empreinte carbone adapté à la Polynésie française, initialement conçu sur la base de nosgestesclimat, développé par <a href="https://datagir.ademe.fr/" target="_blank">Datagir</a>.</span>
				</div>
				<div
					css={`
						display: none;/*flex*/
						align-items: center;
						justify-content: center;
						margin-bottom: 1rem;
						img {
							margin: 0 0.6rem;
						}
					`}
				>
					<Marianne
						role="img"
						aria-label="République Française"
						css="height: 6rem; margin-right: .6rem"
					/>
					<a href="https://ademe.fr">
						<LogoADEME />
					</a>
					<a href="https://abc-transitionbascarbone.fr">
						<img
							css="height: 2rem; margin-left: 1rem !important"
							src="https://www.nosgestesclimat-pf.org/illustrations/logo-ABC-web.png"
							alt="Logo de l'Association pour la transition Bas Carbone"
						/>
					</a>
				</div>
				<div
					css={`
						display: flex;
						justify-content: center;
						align-items: center;
						flex-wrap: wrap;
						> * {
							margin: 0 0.6rem;
						}
						img {
							font-size: 120%;
						}
					`}
				>
					<Link to="/à-propos">À propos</Link>
					<DocumentationButton />
					<Link to="/diffuser">Diffuser</Link>
					<ProfileLink />
				</div>
			</footer>
		</div>
	)
}

const ProfileLink = () => {
	const { hasData } = useProfileData()
	if (!hasData) return null
	return (
		<animate.fromTop delay="1">
			<div
				css={`
					button {
						padding: 0 0.2rem !important;
						border-radius: 1rem !important;
					}
				`}
			>
				<Link
					to="/profil"
					title="Page profil"
					className="ui__ button plain small"
					css="border-radius: 2rem !important"
				>
					<img
						aria-hidden="true"
						src={openmojiURL('profile')}
						css="width: 2rem; filter: invert(1)"
					/>
				</Link>
			</div>
		</animate.fromTop>
	)
}
